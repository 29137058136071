@use '../../styles/shared/include' as *;

.announcementBar {
	background-color: $cream;
	color: $deepBlue;
	padding: rem(16);
	padding-right: rem(24); // to compensate for scrollbar overlay
	display: flex;
	flex-direction: row;
	gap: rem(16);
	align-items: flex-start;
	height: (var(--announcement-banner-height));
	opacity: 1;
	justify-content: space-between;
	transition: all 0.25s ease-in;

	* {
		overflow-wrap: anywhere;
	}

	:global(a) {
		color: $deepBlue;
		text-decoration: underline;
	}

	// transition: opacity ease-in 1s;
	@media #{$lgn} {
		transition: padding 0.5s ease-in 0.5s;
	}

	.content {		
		font-weight: 400;
		display: flex;
		flex-direction: column;
		gap: rem(8);

		span {
			line-height: 1.25;
			font-weight: 500;
		}

		.size-6 { font-size: rem(16); }
		.size-5 { font-size: rem(17); }
		.size-4 { font-size: rem(19); }
		.size-3 { font-size: rem(20); }
		.size-2 { font-size: rem(21); text-shadow: 0 0 .5px #333; }
		.size-1 { font-size: rem(22); text-shadow: 0 0 .5px #333; }
	}

	.close-btn {
		background: transparent;
		display: flex;
		gap: rem(4);
		align-items: center;
		color: $deepBlue;
		font-size: rem(14);
		font-weight: 500;
		line-height: 140%;
		cursor: pointer;

		span {
			overflow-wrap: break-word;
			display: none;
			@media #{$lgn} {
				display: block;
			}
		}
	}
	&--hide {
		height: 0;
		overflow: hidden;
		padding: 0;
		opacity: 0;
	}

	&--hidden {
		position: absolute;
		top: calc(-1 * var(--announcement-banner-height, 200px));
	}
}
